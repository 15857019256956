import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import logo from "./logo.svg";
import "./App.css";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Link, Typography } from "@mui/material";

const theme = createTheme({
  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <header className="App-header">
          <h1>Orhagen.no</h1>
          <img src={logo} className="App-logo" alt="logo" />
          <Link
            href="https://slektstre.orhagen.no"
            color="inherit"
            underline="hover"
          >
            Slektstre
          </Link>
          <Stack direction="row" spacing={30}>
            {["Ole P.", "Erik", "Tom"].map((name) => {
              return (
                <Typography variant="h1" component="h2">
                  <Link
                    href="https://ole.orhagen.no"
                    color="inherit"
                    underline="hover"
                  >
                    {name}
                  </Link>
                </Typography>
              );
            })}
          </Stack>
          <p>Currently under construction</p>
        </header>
      </ThemeProvider>
    </div>
  );
}

export default App;
